<template>
  <!-- zx 2021-6-10 -->
  <div>
    <!-- <div class="number1" v-lazy:background-image="require('./arithmetic_img/banner.png')"> -->
    <div class="number1">
      <div class="start">
        <img
          v-lazy="require('./arithmetic_img/HJmDtI0i.webp')"
          class="start_img"
        />
      </div>
      <div class="bannert">
        <div class="count1">物联及算法</div>
        <div class="count2">
          安全、稳定、弹性的边缘计算服务，助力您快速搭建自己的边缘实时计算平台
        </div>
      </div>
    </div>
    <div class="number2">
      <div class="img-1">
        <img v-lazy="require('./arithmetic_img/Xow3vc3f.webp')" class="count3" />
      </div>
      <div class="count4">
        <div class="count5">物联及算法产品详细信息</div>
        <div class="count6">
          物联网边缘计算平台为您的IoT业务提供低延时、灵活、<br />安全、便捷的边缘计算服务，节约了您的运维、开发、<br />
          网络带宽等成本消耗。同时与腾讯云的物联网通信、网<br />
          络开发平台、物联网络等物联网服务共同为您提供统一<br />
          、可靠、弹性、联动、协同的物联网服务。
        </div>
      </div>
    </div>
    <div class="number3">
      <div class="count9">
        <div class="count5">功能</div>
        <div class="count6">
          通过物联边缘计算平台可以快速的创建本地的边缘计算节点，并且腾讯云物联网通信的SDK快速完成本地的设备接入，实现本地数据处理。同时通过打通腾讯云的计算服务，来为您提供本地和云端协同服务。
        </div>
      </div>
      <div class="img-2">
        <img v-lazy="require('./arithmetic_img/HKJO6sJP.webp')" class="count7" />
      </div>
    </div>
    <div class="number4">
      <div class="count8">应用场景</div>
      <div class="count14">
        <div class="count10">
          <div class="img-3">
            <img v-lazy="require('./arithmetic_img/82fNIcRm.webp')" class="count11" />
          </div>
          <div class="count12">
            智慧工厂
          </div>
          <div class="count13">
            帮助用户快速搭建靠近工厂物联网设备数据源头的边缘计算平台，提供实时数据采集分析，建立工厂分析模型，感知并且降低环境和生产过程中的风险，提升生产的效率，优化生产的成本。
          </div>
        </div>
        <div class="count10 ml30">
          <div class="img-3">
            <img
              v-lazy="require('./arithmetic_img/DlZjZhYH.webp')"
              class="count11"
            />
          </div>

          <div class="count12">
            智慧楼宇
          </div>
          <div class="count13">
            帮助用户快速搭建靠近楼宇物联网设备数据源头的边缘计算平台，完成实时数据的采集、
            清洗、存储，及各种楼宇应用场景的数据分析，分析楼宇环境，监控楼宇风险，优化楼宇调度，降低楼宇能耗。
          </div>
        </div>
      </div>
    </div>

    <div>
      <Header></Header>
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.number1 {
  height: 600px;
  /* background-repeat:  no-repeat; */
  /* background-size: 100% 100%; */
}
.start_img {
  height: 600px;
  width: 100%;
}
.start {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
}
.bannert {
  position: absolute;
  left: 361px;
  top: 230px;
}
.count1 {
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  // padding-top: 230px;
  // padding-left: 360px;
  text-align: left;
}
.count1 {
  animation: sport 0.5s;
  transform: translateY(0px);
}
.count2 {
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  text-align: left;
  margin-top: 20px;
  // padding-left: 360px;
  width: 660px;
}
.count2 {
  animation: sport 0.5s;
  transform: translateY(0px);
}
.number2 {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}
.count3 {
  width: 600px;
  height: 357px;
}
.img-1 {
  width: 600px;
  height: 357px;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 80px;
    height: 80px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    width: 600px;
    height: 357px;
  }
}
.count4 {
  height: 357px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 600px;
}
.count5 {
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  margin-top: 64px;
  margin-left: 55px;
  text-align: left;
}
.count6 {
  width: 495px;
  color: rgba(153, 153, 153, 1);
  font-size: 20px;
  line-height: 32px;
  text-align: left;
  margin-top: 24px;
  margin-left: 55px;
}
.number3 {
  margin-top: 30px;
  display: flex;
  margin-bottom: 100px;
  justify-content: center;
}
.count9 {
  height: 324px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 2px 12px 4px rgba(0, 0, 0, 0.04);
  width: 600px;
}
.count7 {
  height: 324px;
  width: 600px;
}
.img-2 {
  height: 324px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 80px;
    height: 80px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 324px;
    width: 600px;
  }
}
.number4 {
  background-color: #f6f8fb;
  padding-top: 80px;
  padding-bottom: 100px;
}
.count8 {
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
}
.count14 {
  display: flex;
  justify-content: center;
  margin-top: 69px;
}
.count10 {
  height: 697px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 585px;
}
.count11 {
  width: 585px;
  height: 420px;
}
.img-3 {
  width: 585px;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 100px;
    height: 100px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    width: 585px;
    height: 420px;
  }
}
.count12 {
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  margin-top: 36px;
  text-align: left;
  margin-left: 48px;
}
.count13 {
  width: 504px;
  color: rgba(153, 153, 153, 1);
  font-size: 20px;
  line-height: 36px;
  text-align: left;
  margin-top: 24px;
  margin-left: 48px;
}
.ml30 {
  margin-left: 30px;
}
</style>
